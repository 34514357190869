import { useAuth0 } from "@auth0/auth0-react";
import CloseIcon from "@mui/icons-material/Close";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import AccountCircle from "@mui/icons-material/Search";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Unstable_Grid2";
import { debounce } from "lodash";
import { useCallback, useEffect, useState, useSyncExternalStore } from "react";
import { useInView } from "react-intersection-observer";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BounceLoader } from "react-spinners";
import SpAvatar from "../../Components/SpAvatar";
import { handleGetAccessToken } from "../../Helpers/auth";
import { store as meStore } from "../../Stores/Me";
import {
	actions as usersActions,
	store as usersStore,
} from "../../Stores/Users";
import ConfirmDeleteDialog from "./ConfirmDeleteDialog";

function getInitials(user) {
	return `${user.firstName?.charAt(0) ?? ""}${user.lastName.charAt(0) ?? ""}`;
}

export default () => {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const [userQuery, setUserQuery] = useState("");
	const [anchorEl, setAnchorEl] = useState(null);
	const [selectedUser, setSelectedUser] = useState(null);
	const [showSnackbar, setShowSnackbar] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarMessageSeverity, setSnackbarMessageSeverity] = useState("");
	const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);

	const { ref, inView, entry } = useInView({
		threshold: 0,
	});

	const open = Boolean(anchorEl);

	const handleOptionsClick = (event, user) => {
		setAnchorEl(event.currentTarget);
		setSelectedUser(user);
	};

	const handleOptionsClose = () => {
		setAnchorEl(null);
		setSelectedUser(null);
	};

	const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

	const namespace = searchParams.get("n");

	const {
		users,
		next,
		lastParams,
		isFetching: isFetchingUsers,
		isFetchingNext: isFetchingNextUsers,
		isFetched: hasFetchedUsers,
		error: fetchUsersError,
		isDeleted: hasDeletedUser,
		isDeleting: isDeletingUser,
		deletedUserIds,
		lastDeleteParams,
		deleteError: deleteUserError,
	} = useSyncExternalStore(usersStore.subscribeToUsers, usersStore.getUsers);

	const {
		permissions,
		sudodAs,
		isFetched: hasFetchedMe,
		isFetching: isFetchingMe,
	} = useSyncExternalStore(meStore.subscribeToMe, meStore.getMe);

	// biome-ignore lint/correctness/useExhaustiveDependencies: this is a false positive biome err?
	const debounceFetchUsers = useCallback(
		debounce(
			(query) =>
				handleGetAccessToken(getAccessTokenSilently, loginWithRedirect).then(
					(token) => {
						usersActions.fetchUsers(token, { namespace, query }, sudodAs);
					},
				),
			500,
			{
				trailing: true,
			},
		),
		[namespace, sudodAs],
	);

	useEffect(() => {
		if (hasDeletedUser && !deleteUserError) {
			setSnackbarMessageSeverity("success");
			setSnackbarMessage("User deleted successfully.");
			setShowSnackbar(true);
			usersActions.resetDeleteUserState();
		}
	}, [hasDeletedUser, deleteUserError]);

	useEffect(() => {
		if (deleteUserError) {
			setSnackbarMessageSeverity("error");
			setSnackbarMessage("Something went wrong. Please try again later.");
			setShowSnackbar(true);
			usersActions.resetDeleteUserState();
		}
	}, [deleteUserError]);

	//infinite scroll and fetch
	useEffect(() => {
		if (inView && !isFetchingUsers && hasFetchedUsers) {
			handleGetAccessToken(getAccessTokenSilently, loginWithRedirect).then(
				(token) => {
					usersActions.fetchNext(token, sudodAs);
				},
			);
		}
	}, [
		getAccessTokenSilently,
		sudodAs,
		loginWithRedirect,
		inView,
		isFetchingUsers,
		hasFetchedUsers,
	]);

	//reset users in list
	useEffect(() => {
		if (
			lastParams?.namespace &&
			lastParams.namespace !== namespace &&
			hasFetchedUsers
		) {
			usersActions.resetState();
		}
	}, [namespace, lastParams, hasFetchedUsers]);

	//initial fetch
	useEffect(() => {
		if (isFetchingUsers || !namespace || lastParams?.namespace === namespace) {
			return;
		}
		handleGetAccessToken(getAccessTokenSilently, loginWithRedirect).then(
			(token) => {
				usersActions.fetchUsers(token, { namespace }, sudodAs);
			},
		);
	}, [
		getAccessTokenSilently,
		sudodAs,
		loginWithRedirect,
		isFetchingUsers,
		namespace,
		lastParams,
	]);

	// if user is not authorized to see page, adios
	useEffect(() => {
		if (isFetchingMe || !namespace || !hasFetchedMe) {
			return;
		}

		const authorization = permissions.find(
			(p) =>
				p.name === "users:readWrite" && p.definition.namespace === namespace,
		);

		if (!authorization) {
			navigate(`/n=${namespace}`);
		}
	}, [isFetchingMe, hasFetchedMe, permissions, namespace, navigate]);

	const onSearchUserKeyPress = (event) => {
		setUserQuery(event.target.value);
		if (event.key === "Enter") {
			debounceFetchUsers(event.target.value);
		}
	};

	const isDeletingSelectedUser = (userId) => {
		return isDeletingUser && lastDeleteParams.userId === userId;
	};

	useEffect(() => {
		debounceFetchUsers(userQuery);
	}, [userQuery, debounceFetchUsers]);

	return (
		<>
			<Box px={4} borderBottom="1px solid #dbdbdb">
				<Box className="pageTitle">User Management</Box>
			</Box>

			<Grid container p={4}>
				<Grid xs={4}>
					<TextField
						InputProps={{
							startAdornment: (
								<InputAdornment
									position="start"
									sx={{
										cursor: "pointer",
									}}
									onClick={() => debounceFetchUsers(userQuery)}
								>
									<AccountCircle
										sx={{
											fontColor: "#65c8e9",
										}}
									/>
								</InputAdornment>
							),
							endAdornment: (
								<InputAdornment
									position="end"
									sx={{
										cursor: "pointer",
									}}
									onClick={() => {
										setUserQuery("");
										debounceFetchUsers("");
									}}
								>
									<CloseIcon
										sx={{
											fontColor: "#65c8e9",
										}}
									/>
								</InputAdornment>
							),
						}}
						label="User Search"
						value={userQuery}
						onChange={onSearchUserKeyPress}
						fullWidth
					/>
				</Grid>
				<Grid xs={7} />

				<Grid
					xs={1}
					display="flex"
					alignItems="center"
					justifyContent={"center"}
				>
					<Button
						endIcon={<PersonAddAltIcon />}
						onClick={() => navigate("/adduser")}
					>
						Add User
					</Button>
				</Grid>
			</Grid>
			{isFetchingUsers || !hasFetchedUsers ? (
				<Box
					sx={{
						display: "flex",
						height: "50vh",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<BounceLoader color="#44ABDF" size={80} />
				</Box>
			) : users?.length ? (
				<Stack spacing={2} sx={{ px: 4 }} divider={<Divider flexItem />}>
					<Grid
						container
						sx={{
							color: "#03143d",
							fontWeight: 600,
							fontSize: "1em",
						}}
					>
						<Grid xs={4}>User</Grid>
						<Grid xs={4} display="flex" alignItems="center">
							Email
						</Grid>
						<Grid xs={3} display="flex" alignItems="center">
							Roles
						</Grid>
						<Grid />
					</Grid>
					{users
						.filter((u) => !deletedUserIds.includes(u.altId))
						.map((user) => (
							<Grid key={user._id} container>
								<Grid xs={4}>
									<Stack direction="row">
										<SpAvatar
											letters={getInitials(user)}
											width={37}
											height={37}
										/>
										<Stack sx={{ ml: 1 }}>
											<Box>{`${user.firstName} ${user.lastName}`}</Box>
											<Box
												sx={{ color: "#aaa", fontSize: ".75em" }}
											>{`${user.altId}`}</Box>
										</Stack>
									</Stack>
								</Grid>
								<Grid xs={4} display="flex" alignItems="center">
									{user.email}
								</Grid>
								<Grid xs={3} display="flex" alignItems="center">
									{user.roles
										.filter((r) => {
											return r.namespace === namespace;
										})
										.map((r) => r.name)
										.join(", ")}
								</Grid>
								<Grid
									xs={1}
									display="flex"
									alignItems="center"
									justifyContent={"center"}
								>
									{isDeletingSelectedUser(user.altId) ? (
										<BounceLoader color="#44ABDF" size={37} />
									) : (
										<IconButton
											onClick={(event) => {
												handleOptionsClick(event, user);
											}}
										>
											<MoreVertIcon />
										</IconButton>
									)}
								</Grid>
							</Grid>
						))}
					{next ? (
						<div ref={ref}>
							<Grid container sx={{ pb: 2 }}>
								<Grid display="flex">
									<BounceLoader color="#44ABDF" size={37} />
								</Grid>
								<Grid sx={{ ml: 1 }} display="flex" alignItems="center">
									Loading...
								</Grid>
							</Grid>
						</div>
					) : (
						<></>
					)}
				</Stack>
			) : (
				<Box
					height="50%"
					width="100%"
					alignItems="center"
					display="flex"
					justifyContent="center"
				>
					<h3>No users found</h3>
				</Box>
			)}
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleOptionsClose}
				MenuListProps={{
					"aria-labelledby": "basic-button",
				}}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
			>
				<MenuItem
					onClick={() => {
						window.sessionStorage.setItem("sudodAs", selectedUser.altId);
						window.location.assign(`${window.location.origin}/?n=${namespace}`);
					}}
				>
					Sudo As
				</MenuItem>
				<MenuItem onClick={() => setShowConfirmDeleteDialog(true)}>
					Delete
				</MenuItem>
			</Menu>
			<Snackbar
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
				open={showSnackbar}
				autoHideDuration={6000}
				onClose={() => {
					setShowSnackbar(false);
				}}
			>
				<Alert severity={snackbarMessageSeverity} sx={{ width: "100%" }}>
					{snackbarMessage}
				</Alert>
			</Snackbar>
			<ConfirmDeleteDialog
				open={showConfirmDeleteDialog}
				name={`${selectedUser?.firstName} ${selectedUser?.lastName}`}
				onCancel={() => {
					setShowConfirmDeleteDialog(false);
					handleOptionsClose();
				}}
				onContinue={() => {
					handleGetAccessToken(getAccessTokenSilently, loginWithRedirect).then(
						(token) => {
							usersActions.deleteUser(token, selectedUser.altId, sudodAs);
						},
					);
					setShowConfirmDeleteDialog(false);
					handleOptionsClose();
				}}
			/>
		</>
	);
};
