export async function handleGetAccessToken(getAccessTokenSilently, loginWithRedirect) {
    try {
        const token = await getAccessTokenSilently();
        return token;
    } catch (error) {
        await loginWithRedirect();
    }
}

export function isExplorerAuthorized(permissions, namespace ) {
    if (!permissions || permissions.length === 0) {
      return false;
    }
  
    const exists = permissions.find(
      (p) => p.definition.namespace === namespace && p.name === 'lookerExplorer:read'
    );
  
    return exists;
  }
  