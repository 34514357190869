import * as nodeServer from "../Clients/NodeServer";

const meInitialState = {
	user: null,
	permissions: [],
	error: null,
	sudodAs: window.sessionStorage.getItem("sudodAs"),
	isFetching: false,
	isFetched: false,
	isUpdating: false,
	isUpdated: false,
};

let _me = { ...meInitialState };

const resetMyPasswordInitialState = {
	error: null,
	isFetching: false,
	isFetched: false,
};

let _resetMyPassword = {
	...resetMyPasswordInitialState,
};

const meSubscribers = new Set();
const resetMyPasswordSubscribers = new Set();

const store = {
	getMe: () => {
		return _me;
	},
	setMe: async (me) => {
		_me = me;
		for (const cb of meSubscribers) {
			cb();
		}
	},
	subscribeToMe(callback) {
		meSubscribers.add(callback);
		return () => meSubscribers.delete(callback);
	},
	setResetMyPassword: (resetMyPassword) => {
		_resetMyPassword = resetMyPassword;
		for (const cb of resetMyPasswordSubscribers) {
			cb();
		}
	},
	getResetMyPassword: () => {
		return _resetMyPassword;
	},
	subscribeToResetMyPassword(callback) {
		resetMyPasswordSubscribers.add(callback);
		return () => resetMyPasswordSubscribers.delete(callback);
	},
};

const actions = {
	async fetchMe(token, assumeUserId) {
		if (_me.isFetching) {
			return;
		}
		store.setMe({
			..._me,
			isFetching: true,
		});
		try {
			const resp = await nodeServer.fetchMe(token, assumeUserId);
			store.setMe({
				..._me,
				...resp.data,
				isFetching: false,
				isFetched: true,
			});
		} catch (e) {
			store.setMe({
				..._me,
				error: e,
				isFetching: false,
				isFetched: true,
			});
		}
	},
	async updateMe(token, firstName, lastName, namespace, assumeUserId) {
		if (_me.isUpdating || _me.isPasswordUpdating) {
			return;
		}
		store.setMe({
			..._me,
			isUpdating: true,
		});
		try {
			const resp = await nodeServer.updateMe(
				token,
				{
					firstName,
					lastName,
					namespace,
				},
				assumeUserId,
			);
			store.setMe({
				..._me,
				...resp.data,
				isUpdating: false,
				isUpdated: true,
			});
		} catch (e) {
			store.setMe({
				..._me,
				error: e,
				isUpdating: false,
				isUpdated: false,
			});
		}
	},
	async resetMyPassword(token, assumeUserId) {
		if (_resetMyPassword.isFetching) {
			return;
		}
		store.setResetMyPassword({
			..._resetMyPassword,
			isFetching: true,
		});
		try {
			await nodeServer.requestPasswordReset(token, assumeUserId);
			store.setResetMyPassword({
				..._resetMyPassword,
				isFetching: false,
				isFetched: true,
			});
		} catch (e) {
			store.setResetMyPassword({
				..._resetMyPassword,
				error: e,
				isFetching: false,
				isFetched: true,
			});
		}
	},
	resetState() {
		store.setMe({ ...meInitialState });
		store.setResetMyPassword({ ...resetMyPasswordInitialState });
	},
};

export { store, actions };
