import Box from "@mui/material/Box";
import { useEffect, useState, useSyncExternalStore } from "react";
import "../styles.css";
import "./styles.css";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Unstable_Grid2";
import { handleGetAccessToken } from "../../Helpers/auth";
import { actions as meActions, store as meStore } from "../../Stores/Me";
import ChangePasswordDialog from "./ChangePasswordDialog";

// https://mui.com/material-ui/react-text-field/#helper-text
const EMPTY_HELPER_TEXT = " ";

export default () => {
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [firstNameHelperText, setFirstNameHelperText] =
		useState(EMPTY_HELPER_TEXT);
	const [firstNameError, setFirstNameError] = useState(false);
	const [lastNameHelperText, setLastNameHelperText] =
		useState(EMPTY_HELPER_TEXT);
	const [lastNameError, setLastNameError] = useState(false);
	const [showSnackbar, setShowSnackbar] = useState(false);
	const [showChangePasswordDialog, setShowChangePasswordDialog] =
		useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarMessageSeverity, setSnackbarMessageSeverity] = useState("");
	const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

	const {
		user,
		sudodAs,
		isFetched: fetchedUser,
		isUpdated: isUserUpdated,
		isUpdating: isUpdatingUser,
		error: updateUserError,
	} = useSyncExternalStore(meStore.subscribeToMe, meStore.getMe);

	const {
		isFetching: resetingPassword,
		isFetched: resetPasswordComplete,
		error: resetPasswordError,
	} = useSyncExternalStore(
		meStore.subscribeToResetMyPassword,
		meStore.getResetMyPassword,
	);

	const email = user?.email ?? "";

	useEffect(() => {
		if (user) {
			setFirstName(user.firstName);
			setLastName(user.lastName);
		}
	}, [user]);

	useEffect(() => {
		if (!isUpdatingUser && isUserUpdated && !updateUserError) {
			setSnackbarMessageSeverity("success");
			setSnackbarMessage("Changes saved!");
			setShowSnackbar(true);
		}
	}, [isUserUpdated, isUpdatingUser, updateUserError]);

	useEffect(() => {
		if (updateUserError) {
			setSnackbarMessageSeverity("error");
			setSnackbarMessage("Something went wrong. Please try again later.");
			setShowSnackbar(true);
		}
	}, [updateUserError]);

	useEffect(() => {
		if (resetPasswordError) {
			setSnackbarMessageSeverity("error");
			setSnackbarMessage(
				"Could not send password change request. Please try again later.",
			);
			setShowSnackbar(true);
		}
	}, [resetPasswordError]);

	useEffect(() => {
		if (!resetingPassword && resetPasswordComplete && !resetPasswordError) {
			setSnackbarMessageSeverity("success");
			setSnackbarMessage("Password request sent! Check your email.");
			setShowSnackbar(true);
		}
	}, [resetingPassword, resetPasswordComplete, resetPasswordError]);

	return (
		<>
			<Box px={4} borderBottom="1px solid #dbdbdb">
				<Box className="pageTitle">Account</Box>
			</Box>

			<Grid
				container
				py={6}
				rowSpacing={4}
				columnSpacing={{ xl: 4, xs: 1 }}
				sx={{
					color: "black",
				}}
			>
				<Grid md={12} xs={12} px={6} className="fieldSectionTitle">
					User Details
				</Grid>
				<Grid md={1} xs={1} />
				<Grid md={5} xs={10}>
					<TextField
						label="First Name"
						value={firstName}
						onChange={(e) => {
							setFirstName(e.target.value);
							if (e.target.value) {
								setFirstNameError(false);
								setFirstNameHelperText(EMPTY_HELPER_TEXT);
							} else {
								setFirstNameHelperText("First name is required");
								setFirstNameError(true);
							}
						}}
						fullWidth
						helperText={firstNameHelperText}
						error={firstNameError}
					/>
				</Grid>
				<Grid md={6} xs={1} />
				<Grid md={1} xs={1} />
				<Grid md={5} xs={10}>
					<TextField
						label="Last Name"
						value={lastName}
						onChange={(e) => {
							setLastName(e.target.value);
							if (e.target.value) {
								setLastNameError(false);
								setLastNameHelperText(EMPTY_HELPER_TEXT);
							} else {
								setLastNameHelperText("Last name is required");
								setLastNameError(true);
							}
						}}
						fullWidth
						helperText={lastNameHelperText}
						error={lastNameError}
					/>
				</Grid>
				<Grid md={6} xs={1} />
				<Grid md={1} xs={2} />
				<Grid md={5} xs={9}>
					<TextField label="Email" value={email} disabled fullWidth />
				</Grid>
				<Grid md={6} xs={1} />
				<Grid md={5} xs={1} />
				<Grid md={1} xs={2}>
					<LoadingButton
						loading={isUpdatingUser}
						variant="contained"
						fullWidth
						disabled={!fetchedUser || firstNameError || lastNameError}
						onClick={() =>
							handleGetAccessToken(
								getAccessTokenSilently,
								loginWithRedirect,
							).then((token) =>
								meActions.updateMe(
									token,
									firstName,
									lastName,
									user?.namespace,
									sudodAs,
								),
							)
						}
					>
						Save
					</LoadingButton>
				</Grid>
				<Grid md={0} xs={9} />
			</Grid>

			<Grid
				container
				rowSpacing={4}
				columnSpacing={{ xl: 4, xs: 1 }}
				py={6}
				sx={{
					color: "black",
				}}
			>
				<Grid md={1} xs={12} px={6} className="fieldSectionTitle">
					Password
				</Grid>
				<Grid md={0} xs={1} display={{ md: "none" }} />
				<Grid md={2} xs={5}>
					<LoadingButton
						variant="outlined"
						fullWidth
						loading={resetingPassword}
						disabled={resetPasswordComplete}
						onClick={() => {
							setShowChangePasswordDialog(true);
						}}
					>
						{resetPasswordComplete ? "Request Sent" : "Change Password"}
					</LoadingButton>
				</Grid>
				<Grid md={9} xs={6} />
			</Grid>

			<Grid
				container
				rowSpacing={4}
				columnSpacing={{ xl: 4, xs: 1 }}
				sx={{
					color: "black",
				}}
			>
				<Grid
					md={1}
					xs={1}
					display="flex"
					justifyContent="right"
					alignItems="center"
				/>
			</Grid>
			<Snackbar
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
				open={showSnackbar}
				autoHideDuration={6000}
				onClose={() => setShowSnackbar(false)}
			>
				<Alert severity={snackbarMessageSeverity} sx={{ width: "100%" }}>
					{snackbarMessage}
				</Alert>
			</Snackbar>
			<ChangePasswordDialog
				open={showChangePasswordDialog}
				onCancel={() => {
					setShowChangePasswordDialog(false);
				}}
				onContinue={() => {
					setShowChangePasswordDialog(false);
					handleGetAccessToken(getAccessTokenSilently, loginWithRedirect).then(
						(token) => meActions.resetMyPassword(token),
					);
				}}
			/>
		</>
	);
};
