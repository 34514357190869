import axios from "axios";
import { pickBy } from "lodash";

//TODO remove X-Forwarded-Proto when deploying, this is only intended to aid local development

export function lookerAcquireSession(token, assumeUserId) {
	const queryParams = pickBy({
		assumeUserId,
	});
	return axios.get(`${import.meta.env.VITE_API_URL}/looker/aquireSession`, {
		headers: {
			Authorization: `Bearer ${token}`,
			"X-Forwarded-Proto": "https",
		},
		params: queryParams,
		withCredentials: true,
	});
}

export function lookerGenerateTokens(
	token,
	apiToken,
	navigationToken,
	assumeUserId,
) {
	const queryParams = pickBy({
		assumeUserId,
	});
	return axios.put(
		`${import.meta.env.VITE_API_URL}/looker/generateTokens`,
		{
			api_token: apiToken,
			navigation_token: navigationToken,
		},
		{
			headers: {
				Authorization: `Bearer ${token}`,
				"X-Forwarded-Proto": "https",
			},
			params: queryParams,
			withCredentials: true,
		},
	);
}

export function fetchMe(token, assumeUserId) {
	const queryParams = pickBy({
		assumeUserId,
	});
	return axios.get(`${import.meta.env.VITE_API_URL}/user/me`, {
		headers: {
			Authorization: `Bearer ${token}`,
			"X-Forwarded-Proto": "https",
		},
		params: queryParams,
	});
}

export function getUsers(
	token,
	{ namespace, pageSize, nextToken: next, query },
	assumeUserId,
) {
	const santiziedQueryParams = pickBy({
		namespace,
		pageSize,
		next,
		query,
		assumeUserId,
	});

	return axios.get(`${import.meta.env.VITE_API_URL}/users`, {
		headers: {
			Authorization: `Bearer ${token}`,
			"X-Forwarded-Proto": "https",
		},
		params: santiziedQueryParams,
	});
}

export function updateMe(
	token,
	{ firstName, lastName, namespace },
	assumeUserId,
) {
	const queryParams = pickBy({
		assumeUserId,
	});
	return axios.put(
		`${import.meta.env.VITE_API_URL}/user/me`,
		{
			firstName,
			lastName,
			namespace,
		},
		{
			headers: {
				Authorization: `Bearer ${token}`,
				"X-Forwarded-Proto": "https",
			},
			params: queryParams,
		},
	);
}

export function createUser(
	token,
	{ firstName, lastName, email, namespace },
	{ skipPasswordEmail, assumeUserId },
) {
	const queryParams = pickBy({
		assumeUserId,
		skipPasswordEmail,
	});
	return axios.post(
		`${import.meta.env.VITE_API_URL}/users`,
		{
			firstName,
			lastName,
			namespace,
			email,
			roles: [
				{
					name: "lookerViewer",
					namespace: namespace,
				},
			],
		},
		{
			headers: {
				Authorization: `Bearer ${token}`,
				"X-Forwarded-Proto": "https",
			},
			params: queryParams,
		},
	);
}

export function deleteUser(token, userId, assumeUserId) {
	const queryParams = pickBy({
		assumeUserId,
	});
	return axios.delete(`${import.meta.env.VITE_API_URL}/users/${userId}`, {
		headers: {
			Authorization: `Bearer ${token}`,
			"X-Forwarded-Proto": "https",
		},
		params: queryParams,
	});
}

export function requestPasswordReset(token, assumeUserId) {
	const queryParams = pickBy({
		assumeUserId,
	});
	return axios.put(
		`${import.meta.env.VITE_API_URL}/user/me/changePassword`,
		null,
		{
			headers: {
				Authorization: `Bearer ${token}`,
				"X-Forwarded-Proto": "https",
			},
			params: queryParams,
		},
	);
}

export function getDashboardUrl(token, slug, assumeUserId) {
	const queryParams = pickBy({
		assumeUserId,
	});
	return axios.get(
		`${import.meta.env.VITE_API_URL}/looker/dashboards/${slug}`,
		{
			headers: {
				Authorization: `Bearer ${token}`,
				"X-Forwarded-Proto": "https",
			},
			params: queryParams,
		},
	);
}

export function getDashboards(token, assumeUserId) {
	const queryParams = pickBy({
		assumeUserId,
	});
	return axios.get(`${import.meta.env.VITE_API_URL}/looker/dashboards`, {
		headers: {
			Authorization: `Bearer ${token}`,
			"X-Forwarded-Proto": "https",
		},
		params: queryParams,
	});
}
