import Avatar from "@mui/material/Avatar";

export default ({
	width = "28px",
	height = "28px",
	fontSize = "1em",
	letters = "",
	backgroundColor = "#65c8e9",
}) => {
	return (
		<Avatar
			sx={{
				width,
				height,
				backgroundColor,
			}}
		>
			<span style={{ fontSize }}>{letters ?? ""}</span>
		</Avatar>
	);
};
