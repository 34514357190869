import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useSyncExternalStore } from "react";
import { handleGetAccessToken } from "../../Helpers/auth";
import {
	actions as dashboardActions,
	store as dashboardStore,
} from "../../Stores/Dashboard";
import { store as meStore } from "../../Stores/Me";

import LookerEmbed from "./components/LookerEmbed";
import "./styles.css";
import Box from "@mui/material/Box";
import { useNavigate, useSearchParams } from "react-router-dom";
import useSelectedDashboard from "../../Hooks/useSelectedDashboard";

// TODO error component
export default () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();
	const {
		error: fetchDashboardsError,
		isFetching: fetchingDashboards,
		isFetched: hasFetchedDashboards,
	} = useSyncExternalStore(
		dashboardStore.subscribeToDashboards,
		dashboardStore.getDashboards,
	);

	const { sudodAs } = useSyncExternalStore(
		meStore.subscribeToMe,
		meStore.getMe,
	);

	const selectedNamespace = searchParams.get("n");
	const selectedDashboard = useSelectedDashboard(selectedNamespace);

	useEffect(() => {
		if (selectedDashboard) {
			document.title = `SailPlan - ${selectedDashboard.displayName}`;
		}
	}, [selectedDashboard]);
	const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

	//handle default dashboard selection case
	useEffect(() => {
		if (!hasFetchedDashboards || !selectedNamespace) {
			return;
		}
		const defaultDashboard =
			dashboardStore.getDefaultDashboard(selectedNamespace);

		if (!selectedDashboard && defaultDashboard) {
			const filters = JSON.stringify(
				dashboardStore.getFilters(selectedNamespace, defaultDashboard.slug),
			);
			navigate(
				`/dashboard/${defaultDashboard.slug}?filters=${filters}&n=${selectedNamespace}`,
				{ replace: true },
			);
		}
	}, [selectedDashboard, selectedNamespace, navigate, hasFetchedDashboards]);

	//initialize dashboard filters params
	useEffect(() => {
		if (!selectedDashboard || !selectedNamespace) {
			return;
		}
		const searchParamsFilters = searchParams.get("filters");
		const cachedFilters = dashboardStore.getFilters(
			selectedNamespace,
			selectedDashboard.slug,
		);
		if (
			searchParamsFilters &&
			searchParamsFilters !== JSON.stringify(cachedFilters)
		) {
			const filters = JSON.parse(searchParamsFilters) ?? cachedFilters;
			setSearchParams(
				{
					filters: JSON.stringify(filters),
					n: selectedNamespace,
				},
				{
					replace: true,
				},
			);

			dashboardActions.setFilter(
				selectedNamespace,
				selectedDashboard.slug,
				filters,
			);
		} else if (!searchParamsFilters) {
			setSearchParams(
				{
					filters: JSON.stringify(cachedFilters),
					n: selectedNamespace,
				},
				{
					replace: true,
				},
			);
		}
	}, [selectedNamespace, selectedDashboard, searchParams, setSearchParams]);

	//fetch dashboard if not loaded
	useEffect(() => {
		if (hasFetchedDashboards || fetchingDashboards) {
			return;
		}

		handleGetAccessToken(getAccessTokenSilently, loginWithRedirect).then(
			(token) => {
				dashboardActions.fetchDashboards(token, sudodAs);
			},
		);
	}, [
		sudodAs,
		getAccessTokenSilently,
		loginWithRedirect,
		hasFetchedDashboards,
		fetchingDashboards,
	]);

	function getBody() {
		if (fetchDashboardsError) {
			navigate("/404", { replace: true });
		}
		if (selectedDashboard) {
			const filters = JSON.parse(searchParams.get("filters"));
			return filters ? (
				<LookerEmbed
					sudodAs={sudodAs}
					showLoading={!hasFetchedDashboards || fetchingDashboards}
					lookerId={selectedDashboard?.lookerId}
					filters={JSON.parse(searchParams.get("filters")) ?? {}}
					onFilterChange={(event) => {
						setSearchParams(
							{
								filters: JSON.stringify(event.dashboard.dashboard_filters),
								n: selectedNamespace,
							},
							{
								replace: true,
							},
						);
					}}
				/>
			) : (
				<></>
			);
		}
		if (hasFetchedDashboards && !selectedDashboard) {
			return (
				<Box
					height="100%"
					width="100%"
					alignItems="center"
					gap={2}
					p={2}
					display="flex"
					flexDirection="column"
					justifyContent="center"
					textAlign="center"
				>
					<h2>Nothing to see here.</h2>
					<p>
						Sorry, no dashboards have been found under your account. Please
						contact your administrator.
					</p>
				</Box>
			);
		}
		return <></>;
	}

	return (
		<Box display="flex" flexDirection="column" height="100%">
			<Box flexGrow={1} overflow="hidden" height="100%">
				{getBody()}
			</Box>
		</Box>
	);
};
