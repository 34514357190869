import { useAuth0 } from "@auth0/auth0-react";
import ClearIcon from "@mui/icons-material/Clear";
import ExploreIcon from "@mui/icons-material/Explore";
import LogoutIcon from "@mui/icons-material/Logout";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Unstable_Grid2";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState, useSyncExternalStore } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import BounceLoader from "react-spinners/BounceLoader";
import logo from "./images/SP logo early.png";
import "./styles.css";
import { Avatar } from "@mui/material";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	Menu,
	Typography,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import NamespaceDialog from "../../Components/NamespaceDialog";
import SpAvatar from "../../Components/SpAvatar";
import { handleGetAccessToken, isExplorerAuthorized } from "../../Helpers/auth";
import useSelectedDashboard from "../../Hooks/useSelectedDashboard";
import {
	actions as dashboardActions,
	store as dashboardStore,
} from "../../Stores/Dashboard";
import { actions as meActions, store as meStore } from "../../Stores/Me";
import { store as namespaceStore } from "../../Stores/Namespace";
import Advanced from "./Advanced";
import DashboardMenus from "./DashboardMenus";

function TopBar() {
	const [anchorElUser, setAnchorElUser] = useState(null);
	const [showConfirmLogout, setShowConfirmLogout] = useState(false);
	const [showNamespaceSelection, setShowNamespaceSelection] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();
	const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

	const theme = useTheme();
	const { logout } = useAuth0();

	const selectedNamespace = searchParams.get("n");
	const selectedDashboard = useSelectedDashboard(selectedNamespace);

	const {
		user,
		permissions,
		sudodAs,
		isFetched: isUserFetched,
		isFetching: isFetchingUser,
	} = useSyncExternalStore(meStore.subscribeToMe, meStore.getMe);

	const { available: availableNamespaces } = useSyncExternalStore(
		namespaceStore.subscribeToNamespaces,
		namespaceStore.getNamespaces,
	);

	const { isFetching: isFetchingDashboards, isFetched: hasFetchedDashboards } =
		useSyncExternalStore(
			dashboardStore.subscribeToDashboards,
			dashboardStore.getDashboards,
		);

	const handleOpenUserMenu = (event) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	const handleMenuLogoutClick = () => {
		handleCloseUserMenu();
		setShowConfirmLogout(true);
	};

	const handleExitClick = () => {
		window.sessionStorage.removeItem("sudodAs");
		window.location.assign(
			`${window.location.origin}/users?n=${selectedNamespace}`,
		);
	};

	const handleCancelLogout = () => {
		setShowConfirmLogout(false);
	};

	// Fetch user and permissions
	useEffect(() => {
		if (!isFetchingUser && !isUserFetched) {
			handleGetAccessToken(getAccessTokenSilently, loginWithRedirect).then(
				(token) => {
					meActions.fetchMe(token, sudodAs);
				},
			);
		}
	}, [
		sudodAs,
		isUserFetched,
		isFetchingUser,
		getAccessTokenSilently,
		loginWithRedirect,
	]);

	//Fetch all authorized dashboards
	useEffect(() => {
		if (!isFetchingDashboards && !hasFetchedDashboards) {
			handleGetAccessToken(getAccessTokenSilently, loginWithRedirect).then(
				(token) => {
					dashboardActions.fetchDashboards(token, sudodAs);
				},
			);
		}
	}, [
		sudodAs,
		getAccessTokenSilently,
		loginWithRedirect,
		isFetchingDashboards,
		hasFetchedDashboards,
	]);

	const isExplorer = isExplorerAuthorized(permissions, selectedNamespace);

	return (
		<>
			<AppBar position="static">
				<Toolbar disableGutters variant="dense" sx={{ mx: "15px" }}>
					<Grid container sx={{ width: "100%" }} alignItems="center">
						<Grid xs={2} display="flex">
							<img src={logo} width="158px" height="28px" alt="Sailplan" />
						</Grid>
						<Grid xs={8} display="flex" justifyContent="center">
							{!hasFetchedDashboards || isFetchingDashboards ? (
								<Grid container justify="space-around" spacing={3}>
									<BounceLoader
										color={theme.palette.primary.loader}
										size={30}
									/>
								</Grid>
							) : (
								<DashboardMenus
									selectedDashboard={selectedDashboard}
									selectedNamespace={selectedNamespace}
								/>
							)}
						</Grid>
						<Grid
							xs={2}
							display="flex"
							justifyContent="flex-end"
							alignItems="center"
							gap={3}
						>
							<Advanced
								permissions={permissions.filter(
									(p) => p.definition.namespace === selectedNamespace,
								)}
							/>
							{isExplorer ? (
								<IconButton
									sx={{ p: 0 }}
									onClick={() =>
										navigate(`/explore?n=${selectedNamespace ?? ""}`)
									}
								>
									<ExploreIcon
										titleAccess="Explore"
										sx={{
											color: "white",
											fontSize: "33px",
										}}
									/>
								</IconButton>
							) : (
								<></>
							)}

							<IconButton
								onClick={handleOpenUserMenu}
								disabled={!isUserFetched}
							>
								{isUserFetched ? (
									<SpAvatar
										letters={user?.firstName?.charAt(0)}
										backgroundColor={
											sudodAs === user?.altId ? "#77CDAE" : "#65c8e9"
										}
									/>
								) : (
									<Avatar sx={{ width: 28, height: 28 }} />
								)}
							</IconButton>
							<Menu
								sx={{
									mt: ".2em",
								}}
								id="menu-appbar"
								anchorEl={anchorElUser}
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "right",
								}}
								keepMounted
								transformOrigin={{
									vertical: "top",
									horizontal: "right",
								}}
								open={Boolean(anchorElUser)}
								onClose={handleCloseUserMenu}
							>
								<Box sx={{ width: 220, maxWidth: "100%" }}>
									<Grid container sx={{ pb: 1, px: 1 }}>
										<Grid md={3} display="flex" alignItems={"start"}>
											<SpAvatar
												width={"36px"}
												height={"36px"}
												fontSize={"1.25em"}
												letters={user?.firstName?.charAt(0)}
											/>
										</Grid>
										<Grid md={7}>
											<Stack>
												<Typography>
													{user?.firstName} {user?.lastName}
												</Typography>
												<Typography>{selectedNamespace}</Typography>
												<Link
													to={`/account?n=${selectedNamespace}`}
													style={{ fontSize: 12 }}
													onClick={handleCloseUserMenu}
												>
													Edit Account
												</Link>
											</Stack>
										</Grid>
										<Grid
											md={2}
											display="flex"
											justifyContent="flex-end"
											alignItems={"center"}
										>
											{availableNamespaces?.length > 1 ? (
												<SwapHorizIcon
													onClick={() => {
														handleCloseUserMenu();
														setShowNamespaceSelection(true);
													}}
													sx={{
														cursor: "pointer",
														"&:hover": {
															backgroundColor: "#f0f1f3",
														},
													}}
												/>
											) : (
												<></>
											)}
										</Grid>
									</Grid>

									<Divider />
									<Grid
										container
										sx={{
											p: 1,
											pt: 1,
											cursor: "pointer",
											"&:hover": {
												backgroundColor: "#f0f1f3",
											},
										}}
										onClick={handleMenuLogoutClick}
									>
										<Grid md={3} display="flex" alignItems={"center"}>
											<LogoutIcon />
										</Grid>
										<Grid md={9} display="flex" alignItems={"center"}>
											Logout
										</Grid>
									</Grid>

									<Dialog open={showConfirmLogout} keepMounted>
										<DialogTitle>Logout?</DialogTitle>
										<DialogContent>
											<DialogContentText>
												You will be redirected to the login page.
											</DialogContentText>
										</DialogContent>
										<DialogActions>
											<Button onClick={handleCancelLogout}>Cancel</Button>
											<Button
												onClick={() =>
													logout({
														logoutParams: { returnTo: window.location.origin },
													})
												}
											>
												Logout
											</Button>
										</DialogActions>
									</Dialog>
								</Box>
							</Menu>
						</Grid>
					</Grid>
				</Toolbar>
				<NamespaceDialog
					namespaces={availableNamespaces}
					open={showNamespaceSelection}
					onClose={(value) => {
						setShowNamespaceSelection(false);
					}}
					onSelect={(value) => {
						setShowNamespaceSelection(false);
						setSearchParams({ n: value }, { replace: true });
					}}
				/>
			</AppBar>
			{sudodAs && sudodAs === user?.altId ? (
				<AppBar position="static" color="secondary">
					<Grid container sx={{ px: 4, py: 1 }}>
						<Grid md={6} display="flex" alignItems={"center"}>
							You are sudo'd as {user.firstName} {user.lastName}
						</Grid>
						<Grid md={6} display="flex" justifyContent={"end"}>
							<Grid
								container
								onClick={handleExitClick}
								sx={{
									cursor: "pointer",
								}}
							>
								<Grid display="flex" alignItems={"center"}>
									<ClearIcon />
								</Grid>
								<Grid display="flex" alignItems={"center"}>
									Exit Sudo
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</AppBar>
			) : (
				<></>
			)}
		</>
	);
}

export default TopBar;
