import { Auth0Provider, withAuthenticationRequired } from "@auth0/auth0-react";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import ErrorBoundry from "./Components/ErrorBoundry";
import AddUserPage from "./Pages/AddUserPage";
import DashboardPage from "./Pages/DashboardPage";
import EditAccountPage from "./Pages/EditAccountPage";
import ExplorerPage from "./Pages/ExplorerPage";
import NamespaceContainer from "./Pages/NamespaceContainer";
import NotFound from "./Pages/NotFound";
import UserManagementPage from "./Pages/UserManagementPage";
import DefaultTheme from "./theme";

const Auth0ProviderWithRedirectCallback = ({ children, ...props }) => {
	const navigate = useNavigate();
	const onRedirectCallback = (appState) => {
		navigate(appState?.returnTo || window.location.href);
	};
	return (
		<Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
			{children}
		</Auth0Provider>
	);
};

const ProtectedNamespaceContainer =
	withAuthenticationRequired(NamespaceContainer);

export const App = () => {
	return (
		<ThemeProvider theme={DefaultTheme}>
			<BrowserRouter future={{ v7_startTransition: true }}>
				<Auth0ProviderWithRedirectCallback
					domain={import.meta.env.VITE_AUTH_DOMAIN}
					clientId={import.meta.env.VITE_AUTH_CLIENT_ID}
					authorizationParams={{
						audience: import.meta.env.VITE_AUTH_AUDIENCE,
						redirect_uri: window.location.origin,
					}}
					cacheLocation="localstorage"
					useRefreshTokens={true}
					useRefreshTokensFallback={true}
				>
					<Routes>
						<Route
							element={
								<ErrorBoundry>
									<ProtectedNamespaceContainer />
								</ErrorBoundry>
							}
						>
							<Route path="/" element={<DashboardPage />} />
							<Route path="/dashboard" element={<DashboardPage />} />
							<Route path="/dashboard/:slug" element={<DashboardPage />} />
							<Route path="/explore" element={<ExplorerPage />} />
							<Route path="/:namespace" element={<DashboardPage />} />
							<Route path="/account" element={<EditAccountPage />} />
							<Route path="/users" element={<UserManagementPage />} />
							<Route path="/adduser" element={<AddUserPage />} />
							<Route path="/404" element={<NotFound />} />
							<Route path="/*" element={<NotFound />} />
						</Route>
					</Routes>
				</Auth0ProviderWithRedirectCallback>
			</BrowserRouter>
		</ThemeProvider>
	);
};
